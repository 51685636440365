import '../../styles/flag.css';
import '../../styles/flags/pl-wro.css';


export default function FlagPL_WRO()
{
    const _columns = 30;
    const _backgroundPosition = 50 / _columns;

    let columns = [];
    for (let x = 0; x < _columns; x++) {
        columns.push(x);
    }

    return (
        <div className="flag flag-pl-wro">
            {columns.map((columnIndex) => (
                <div
                    key={columnIndex}
                    className="flag-column"
                    style={{
                        animationDelay: columnIndex * 100 + 'ms',
                        backgroundPositionX: '-' + (_backgroundPosition * columnIndex) + 'px'
                    }}
                />
            ))}
        </div>
    );
}
