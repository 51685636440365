import '../../styles/flag.css';
import '../../styles/flags/pl-krk.css';


export default function FlagPL_KRK()
{
    let columns = [];
    for (let x = 0; x < 30; x++) {
        columns.push(x);
    }

    return (
        <div className="flag flag-pl-krk">
            {columns.map((columnIndex) => (
                <div
                    key={columnIndex}
                    className="flag-column"
                    style={{
                        animationDelay: columnIndex * 100 + 'ms'
                    }}
                />
            ))}
        </div>
    );
}
