export default function DefaultLoader()
{
    return (
        <div className="progress-stacked">
            <div className="progress" style={{width: '35%'}}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" style={{backgroundColor: "#0063d6"}}></div>
            </div>
            <div className="progress" style={{width: '15%'}}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" style={{backgroundColor: "#ffab00"}}></div>
            </div>
            <div className="progress" style={{width: '15%'}}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" style={{backgroundColor: "#ddd"}}></div>
            </div>
            <div className="progress" style={{width: '35%'}}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" style={{backgroundColor: "#009442"}}></div>
            </div>
        </div>
    );
}
