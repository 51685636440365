import React, {useState} from "react";
import '../styles/xmkbox.css';
import Boxes from "./xmkbox/Boxes";
import axios from "axios";
import {config} from "../config";
import Inbox from "./xmkbox/Inbox";
import Thread from "./xmkbox/Thread";
import Header from "./Header";


export default function XMKbox({lang, user, screen, setPreloaderState}: any) {
    const [inbox, setInbox] = useState<any>(null);
    const [threads, setThreads] = useState([]);
    const [thread, setThread] = useState(null);

    const openBox = (id: number) => {
        setPreloaderState(true);

        axios.get(config.api + '/xmkbox/' + id)
            .then(response => {
                setInbox(response.data.box);
                setThreads(response.data.threads);
                setPreloaderState(false);
            })
            .catch(() => {
                setPreloaderState(false);
            });
    }

    const clickOnAddress = () => {
        if (thread) {
            setThread(null);

            return;
        }

        setInbox(null);
    };

    return (
        <div id="xmkbox" style={{display: user === null || screen !== 'xmkbox' ? 'none' : 'block'}}>
            <div className="container">
                <Header />

                <div className="row">
                    <div className="col">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item" aria-current="page" onClick={() => clickOnAddress()}>
                                    xmkBOX
                                </li>
                                {inbox === null ? '' : (<li className="breadcrumb-item" aria-current="page" onClick={() => setThread(null)}>{inbox.address}</li>)}
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            {!inbox && !thread ? <Boxes openBox={openBox} /> : ''}
            {inbox && !thread ? <Inbox lang={lang} inbox={inbox} threads={threads} setThread={setThread} setPreloaderState={setPreloaderState} /> : ''}
            {inbox && thread ? <Thread lang={lang} inbox={inbox} messages={thread} /> : ''}
        </div>
    );
}
