import React from "react";
import '../styles/navigation.css';
import Translator from "../services/Translator";
import walletIcon from "../assets/images/icons/wallet.svg";
import cogIcon from "../assets/images/icons/cog.svg";
import envelopeIcon from "../assets/images/icons/envelope.svg";


export default function Navigation({lang, user, setScreen}: any) {
    return (
        <div id="navigation" style={{display: user === null ? 'none' : 'block'}}>
            <div className="container">
                <div className="row">
                    <div className="col-4" onClick={() => setScreen('documents')} style={{cursor: 'pointer'}}>
                        <img src={walletIcon} alt={Translator.trans(lang, 'navigation.documents')} />
                        {Translator.trans(lang, 'navigation.documents')}
                    </div>
                    <div className="col-4" onClick={() => setScreen('xmkbox')} style={{cursor: 'pointer'}}>
                        <img src={envelopeIcon} alt="xmkBOX" />
                        xmkBOX
                    </div>
                    <div className="col-4" onClick={() => setScreen('settings')} style={{cursor: 'pointer'}}>
                        <img src={cogIcon} alt={Translator.trans(lang, 'navigation.settings')} />
                        {Translator.trans(lang, 'navigation.settings')}
                    </div>
                </div>
            </div>
        </div>
    );
}
