export const pl_pl = {
    "registration": {
        "coat_of_arms": "Herb mały Słonecznej Jaskini",
        "message": "Zaloguj się do swojego konta XMK.app, aby wygenerować kod aktywacyjny dla aplikacji.",
        "code": "Kod logowania",
        "button": "Zarejestruj urządzenie \"%device\""
    },
    "security_status": {
        "danger": "Uwaga! Niebezpieczeństwo!",
        "warning": "Uwaga! Podwyższony stan zagrożenia."
    },
    "navigation": {
        "documents": "Dokumenty",
        "settings": "Ustawienia"
    },
    "documents": {
        "empty_wallet": "Nie masz żadnych dokumentów",
        "identity_card": {
            "name": "Dowód osobisty",
            "identity_card_number": "Numer",
            "given_names": "Imiona",
            "surname": "Nazwisko",
            "sex": "Płeć",
            "date_of_birth": "Data urodzenia",
            "date_of_issue": "Data wydania",
            "expiry_date": "Data ważności"
        },
        "passport": {
            "name_p": "Paszport",
            "name_pd": "Paszport dyplomatyczny",
            "name_ps": "Paszport specjalny",
            "type": "Typ",
            "code": "Kod",
            "number": "Numer",
            "given_names": "Imiona",
            "surname": "Nazwisko",
            "authority": "Organ wyający",
            "date_of_birth": "Data urodzenia",
            "date_of_issue": "Data wydania",
            "date_of_expiry": "Data ważności",
            "representative_office": "Przedstawicielstwo",
            "stamps_and_stickers": "Pieczątki i naklejki"
        },
        "ambassador_card": {
            "name": "Karta Przedstawiciela",
            "given_names": "Imiona",
            "surname": "Nazwisko",
            "representative_office": "Przedstawicielstwo",
            "role": "Rola",
            "roles": {
                "ambassador": "Przedstawiciel"
            }
        }
    },
    "xmkbox": {
        "inbox": {
            "empty": "Nie masz żadnych wiadomości"
        },
        "message": {
            "from": "Od",
            "to": "Do"
        }
    }
}