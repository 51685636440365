import React, {useState} from "react";
import '../../styles/xmkbox.css';
import xmkLogo from "../../assets/images/xmk.png";
import DefaultLoader from "../loaders/DefaultLoader";
import axios from "axios";
import {config} from "../../config";
import Translator from "../../services/Translator";


export default function Inbox({lang, inbox, threads, setThread, setPreloaderState}: any) {
    // const [loadedBoxes, setLoadedBoxes] = useState(false);
    // const [loadingBoxes, setLoadingBoxes] = useState(false);
    // const [boxes, setBoxes] = useState<any[]>([]);
    //
    // if (!loadingBoxes) {
    //     setLoadingBoxes(true);
    //
    // }

    const openThread = (thread: any) => {
        setPreloaderState(true);

        axios.get(config.api + '/xmkbox/' + inbox.id + '/thread/' + thread.id)
            .then(response => {
                setThread(response.data);
                setPreloaderState(false);
            })
            .catch(() => {
                setPreloaderState(false);
            });
    }

    return (
        <div id="xmkbox-inbox">
            <div className="container">
                <div className="row">
                    <div className="col">
                        {
                            threads.length === 0 ? (
                                <div className="alert alert-info">
                                    {Translator.trans(lang, 'xmkbox.inbox.empty')}
                                </div>
                            ) : (
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped threads">
                                        <tbody>
                                        {
                                            threads.map((thread: any, key: number) => {
                                                return (<tr key={key} onClick={() => openThread(thread)} style={{fontWeight: (thread.has_unread_messages ? 700 : 400)}}>
                                                    <td>{thread.title}</td>
                                                    <td>{thread.messages}</td>
                                                </tr>);
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
