import React from 'react';
import './styles/app.css';
import Navigation from "./components/Navigation";
import Registration from "./components/Registration";
import Preloader from "./components/Preloader";
import { useState } from 'react';
import {config} from "./config";
import axios from "axios";
import Documents from "./components/Documents";
import XMKbox from "./components/XMKbox";
import Translator from "./services/Translator";


function App() {
    const [lang] = useState('pl');
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [user, setUser] = useState(null);
    const [userRefreshed, setUserRefreshed] = useState(false);
    const [securityStatus, setSecurityStatus] = useState({type: -1, comment: null});
    const [preloader, setPreloaderState] = useState(false);
    const [screen, setScreen] = useState('documents');

    const refreshUser = () => {
        setPreloaderState(true);

        axios.get(config.api + '/user')
            .then((response) => {
                setUser(response.data);
                setPreloaderState(false);
            })
            .catch((response) => console.log(response));
    }

    const getSecurityStatus = () => {
        if (securityStatus.type >= 0 || securityStatus.type === -1) {
            return;
        }

        axios.get(config.api + '/security-status')
            .then((response) => {
                setSecurityStatus(response.data);
            })
            .catch((response) => console.log(response));
    }

    if (token != null) {
        axios.defaults.headers.common = {
            'Authorization': 'Bearer ' + token
        };

        getSecurityStatus();

        if (user === null && !userRefreshed) {
            setUserRefreshed(true);
            refreshUser();
        }
    }

    // if (config.env === 'dev') {
    //     setInterval(function() {
    //         axios.get(config.api + '/info').then((response) => console.log(response));
    //     }, 10000);
    // }

    return (
        <div className="app">
            <div className="background" />

            {
                securityStatus.type === 2 ? (
                    <div className="container" style={{marginTop: '5px'}}>
                        <div className="row">
                            <div className="col">
                                <div className="alert alert-danger">
                                    <strong>{Translator.trans(lang, 'security_status.danger')}</strong>
                                    &nbsp;{securityStatus.comment !== null ? securityStatus.comment : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''
            }

            {
                securityStatus.type === 1 ? (
                    <div className="container" style={{marginTop: '5px'}}>
                        <div className="row">
                            <div className="col">
                                <div className="alert alert-warning">
                                    <strong>{Translator.trans(lang, 'security_status.warning')}</strong>
                                    &nbsp;{securityStatus.comment !== null ? securityStatus.comment : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''
            }

            {
                securityStatus.type === -1 && securityStatus.comment !== null ? (
                    <div className="container" style={{marginTop: '5px'}}>
                        <div className="row">
                            <div className="col">
                                <div className="alert alert-info">
                                    {securityStatus.comment}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''
            }

            <Preloader show={preloader} />

            <Registration
                lang={lang}
                token={token}
                setPreloaderState={setPreloaderState}
                setToken={setToken}
                refreshUser={refreshUser}
            />

            <Documents
                lang={lang}
                user={user}
                screen={screen}
            />

            <XMKbox
                lang={lang}
                user={user}
                screen={screen}
                setPreloaderState={setPreloaderState}
            />

            <div style={{height: '100px'}} />

            <Navigation
                lang={lang}
                user={user}
                setScreen={setScreen}
            />
        </div>
    );
}

export default App;
