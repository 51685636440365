import React, {useState} from "react";
import '../styles/documents.css';
import coatOfArms from "../assets/images/coat_of_arms.png";


export default function Header({text}: any) {
    return (
        <div className="row">
            <div className="col-xs-2">
                <img src={coatOfArms} alt="xmk.app" className="logo" style={{width: 'auto', height: '40px'}} />
            </div>

            <div className="col-xs-10">
                <h1>{(text === null ? '' : text)}</h1>
            </div>
        </div>
    );
}
