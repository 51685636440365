import React, {useState} from "react";
import '../../styles/document.css';
import Translator from "../../services/Translator";
import DefaultLoader from "../loaders/DefaultLoader";
import axios from "axios";
import {config} from "../../config";
import FlagPL_KRK from "../flags/FlagPL_KRK";
import FlagPL_NT from "../flags/FlagPL_NT";
import FlagPL_WRO from "../flags/FlagPL_WRO";
import FlagPL_WAW from "../flags/FlagPL_WAW";
import FlagXPK from "../flags/FlagXPK";
import backgroundImage from '../../assets/images/documents/ambassador-card.png';


export default function AmbassadorCard({lang, user, show, setCurrentDocument}: any)
{
    const name = 'ambassador-card';
    const [document, setDocument] = useState<any>(null);
    const [loaded, setLoaded] = useState(false);

    if (show && document === null && !loaded) {
        setLoaded(true);
        axios.get(config.api + '/documents/' + name)
            .then(response => {
                setDocument(response.data);
            })
            .catch(response => console.log(response));
    }

    return (
        <div className="card document">
            <div className="card-header" onClick={() => setCurrentDocument(show ? null : name)}>
                {Translator.trans(lang, "documents.ambassador_card.name")}
            </div>

            {
                show && document === null ? (
                    <div className="card-body" style={{background: 'rgb(199,221,246) linear-gradient(57deg, rgba(199,221,246,1) 0%, rgba(148,187,233,1) 100%)'}}>
                        <DefaultLoader />
                    </div>
                ) : ''
            }

            {
                show && document !== null ? (
                    <div className="card-body" style={{background: 'rgb(199,221,246) linear-gradient(57deg, rgba(199,221,246,1) 0%, rgba(148,187,233,1) 100%)'}}>
                        <div className="container-fluid" style={{background: 'url(\'' + backgroundImage + '\') no-repeat top right', backgroundSize: 'contain'}}>
                            <div className="row">
                                <div className="col">
                                    <div style={{float: 'right'}}>
                                        {document.zone === 'krakow' ? <FlagPL_KRK /> : null}
                                        {document.zone === 'nowy-targ' ? <FlagPL_NT /> : null}
                                        {document.zone === 'warsaw' ? <FlagPL_WAW /> : null}
                                        {document.zone === 'wroclaw' ? <FlagPL_WRO /> : null}
                                        {document.zone === 'pigletland' ? <FlagXPK /> : null}
                                    </div>

                                    <strong>{Translator.trans(lang, "documents.ambassador_card.given_names")}</strong>
                                    {document.given_names}

                                    <strong>{Translator.trans(lang, "documents.ambassador_card.surname")}</strong>
                                    {document.surname}

                                    <strong>{Translator.trans(lang, "documents.ambassador_card.representative_office")}</strong>
                                    {document.representative_office}

                                    <strong>{Translator.trans(lang, "documents.ambassador_card.role")}</strong>
                                    {Translator.trans(lang, "documents.ambassador_card.roles." + document.role)}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''
            }
        </div>
    );
}
