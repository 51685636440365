import React, {Component} from "react";
import '../styles/registration.css';
import Translator from "../services/Translator";
import coatOfArms from '../assets/images/coat_of_arms.png';
import { useState } from 'react';
import axios from "axios";
import {config} from "../config";


export default function Registration({lang, token, setPreloaderState, setToken, refreshUser}: any)
{
    const [eseo, setESEO] = useState('');
    const [eseoError, setESEOError] = useState(false);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);

    let device = 'Unknown device';
    if (/iPad/.test(navigator.userAgent)) {
        device = 'iPad';
    } else if (/iPhone/.test(navigator.userAgent)) {
        device = 'iPhone';
    } else if (/Android/.test(navigator.userAgent)) {
        device = 'Android';
    } else if (/Xiaomi/.test(navigator.userAgent)) {
        device = 'Xiaomi';
    } else if (/Redmi/.test(navigator.userAgent)) {
        device = 'Redmi';
    } else if (/Firefox/.test(navigator.userAgent)) {
        device = 'Mozilla Firefox';
    } else if (/Chrome/.test(navigator.userAgent)) {
        device = 'Google Chrome';
    }

    const register = () => {
        setPreloaderState(true);

        let hasErrors = false;
        if (eseo.length < 10) {
            setESEOError(true);
            hasErrors = true;
        } else {
            setESEOError(false);
        }

        if (code.length < 6) {
            setCodeError(true);
            hasErrors = true;
        } else {
            setCodeError(false);
        }

        setTimeout(function() {
        }, 1000);

        if (hasErrors) {
            setPreloaderState(false);
            return;
        }

        axios.post(
            config.api + '/registration',
            {
                eseo: eseo,
                code: code,
                device_name: device
            })
            .then((response) => {
                setESEO('');
                setCode('');

                axios.defaults.headers.common = {
                    'Authorization': 'Bearer ' + response.data.token
                };

                localStorage.setItem('token', response.data.token);
                setToken(response.data.token);

                refreshUser();
            }).catch((response) => {
                setPreloaderState(false);
            });
    }

    return (
        <div id="registration" style={{display: token === null ? 'block' : 'none'}}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <img src={coatOfArms} alt={Translator.trans(lang, 'registration.coat_of_arms')} />

                        <p>{Translator.trans(lang, 'registration.message')}</p>
                    </div>

                    <div className="col-xs-12 col-md-6">
                        <label htmlFor="registration_eseo" className="form-label">ESEO</label>
                        <input
                            type="text"
                            value={eseo}
                            onChange={(e) => setESEO(e.target.value)}
                            className={'form-control' + (eseoError ? ' border-danger' : '')}
                            id="registration_eseo"
                            maxLength={10}
                            minLength={10}
                        />

                        <label htmlFor="registration_code" className="form-label">{Translator.trans(lang, 'registration.code')}</label>
                        <input
                            type="password"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            className={'form-control' + (codeError ? ' border-danger' : '')}
                            id="registration_code"
                            maxLength={6}
                            minLength={6}
                        />

                        <button className="btn btn-primary" onClick={() => register()}>
                            {Translator.trans(lang, 'registration.button', {'%device': device})}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
