import React, {useState} from "react";
import '../styles/documents.css';
import xmkLogo from "../assets/images/xmk.png";
import DefaultLoader from "./loaders/DefaultLoader";
import axios from "axios";
import {config} from "../config";
import Translator from "../services/Translator";
import IdentityCard from "./documents/IdentityCard";
import Passport from "./documents/Passport";
import AmbassadorCard from "./documents/AmbassadorCard";
import Header from "./Header";


export default function Documents({lang, user, screen}: any) {
    const [loadedDocuments, setLoadedDocuments] = useState(false);
    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [currentDocument, setCurrentDocument] = useState(null);

    if (!loadingDocuments) {
        setLoadingDocuments(true);

        axios.get(config.api + '/documents')
            .then(response => {
                setDocuments(response.data);
                setLoadedDocuments(true);

                if (response.data.length === 1) {
                    setCurrentDocument(response.data[0]);
                }
            })
            .catch(() => {
                setLoadedDocuments(true);
            });
    }

    return (
        <div id="documents" style={{display: user === null || screen !== 'documents' ? 'none' : 'block'}}>
            <div className="container">
                <Header />

                {
                    loadedDocuments ? '' : (
                        <div className="row">
                            <div className="col">
                                <DefaultLoader />
                            </div>
                        </div>
                    )
                }

                <div className="row">
                    <div className="col">
                        {
                            loadedDocuments && documents.length === 0 ? (
                                <div className="alert alert-info">
                                    {Translator.trans(lang, 'documents.empty_wallet')}
                                </div>
                            ) : (
                                documents.map((document) => {
                                    if (document === 'identity-card') {
                                        return (<IdentityCard
                                            lang={lang}
                                            ser={user}
                                            show={currentDocument === document}
                                            setCurrentDocument={setCurrentDocument}
                                        />);
                                    }

                                    if (document === 'passport_p') {
                                        return (<Passport
                                            lang={lang}
                                            ser={user}
                                            show={currentDocument === document}
                                            type='P'
                                            setCurrentDocument={setCurrentDocument}
                                        />);
                                    }

                                    if (document === 'passport_pd') {
                                        return (<Passport
                                            lang={lang}
                                            ser={user}
                                            show={currentDocument === document}
                                            type='PD'
                                            setCurrentDocument={setCurrentDocument}
                                        />);
                                    }

                                    if (document === 'passport_ps') {
                                        return (<Passport
                                            lang={lang}
                                            ser={user}
                                            show={currentDocument === document}
                                            type='PS'
                                            setCurrentDocument={setCurrentDocument}
                                        />);
                                    }

                                    if (document === 'ambassador-card') {
                                        return (<AmbassadorCard
                                            lang={lang}
                                            ser={user}
                                            show={currentDocument === document}
                                            setCurrentDocument={setCurrentDocument}
                                        />);
                                    }

                                    return (<p />);
                                })
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
