import React, {useState} from "react";
import '../../styles/document.css';
import '../../styles/animations/animated-text.css';
import Translator from "../../services/Translator";
import DefaultLoader from "../loaders/DefaultLoader";
import axios from "axios";
import {config} from "../../config";
import passportBackgroundA from '../../assets/images/documents/passport_ax.png';
import passportBackground from '../../assets/images/documents/passport_bx.png';
import stampAndra from '../../assets/images/documents/passport/stamps/andra.png';
import stampKolasa from '../../assets/images/documents/passport/stamps/kolasa.png';
import stampMain from '../../assets/images/documents/passport/stamps/main.png';
import stampMouse from '../../assets/images/documents/passport/stamps/mouse.png';
import stampPanda from '../../assets/images/documents/passport/stamps/panda.png';
import stampRabbit from '../../assets/images/documents/passport/stamps/rabbit.png';
import stampTest from '../../assets/images/documents/passport/stamps/test.png';
import stampXMK from '../../assets/images/documents/passport/stamps/xmk.png';


export default function Passport({lang, user, show, type, setCurrentDocument}: any)
{
    const name = 'passport';
    const [document, setDocument] = useState<any>(null);
    const [loaded, setLoaded] = useState(false);

    if (show && document === null && !loaded) {
        setLoaded(true);
        axios.get(config.api + '/documents/' + name + '/' + type.toLowerCase())
            .then(response => {
                setDocument(response.data);
            })
            .catch(response => console.log(response));
    }

    let seriesCode = document !== null ? document.number.substring(0, 1) : 'A';

    return (
        <div className="card document">
            <div className="card-header" onClick={() => setCurrentDocument(show ? null : name + '_' + type.toLowerCase())}>
                {Translator.trans(lang, "documents.passport.name_" + type.toLowerCase())}
            </div>

            {
                show && document === null ? (
                    <div className="card-body" style={{background: '#ffeecc'}}>
                        <DefaultLoader />
                    </div>
                ) : ''
            }

            {
                show && document !== null ? (
                    <div className="card-body" style={{padding: '0 0 15px 0'}}>
                        <div className="container-fluid" style={{
                            background: document.type === 'PD' ?
                                'rgb(228,170,102,0.4) radial-gradient(circle, rgba(228,170,102,0.4) 0%, rgba(242,212,179,0.4) 100%)' :
                                'rgb(255,238,204,0.4) radial-gradient(circle, rgba(255,234,144,0.4) 0%, rgba(255,238,204,0.4) 100%)',
                            paddingTop: '1px'
                        }}>
                            <div style={{margin: '50px auto', textAlign: 'right', marginTop: '40px', marginBottom: '-190px'}}>
                                <img src={seriesCode === 'A' ? passportBackgroundA : passportBackground} alt={Translator.trans(lang, "documents.passport.name")} />
                            </div>

                            <div className="row">
                                <div className="col-5">
                                    <img src={'data:image/png;base64,' + document.photo}
                                         alt={document.given_names + ' ' + document.surname}
                                         className="img-fluid"
                                    />
                                    <p className="animated-text" style={{fontSize: '25px', fontWeight: 700, whiteSpace: 'nowrap', textAlign: 'center'}}>•&nbsp;XMK&nbsp;•</p>
                                </div>

                                <div className="col-7">
                                    <strong>{Translator.trans(lang, "documents.passport.surname")}</strong>
                                    {document.surname}

                                    <strong>{Translator.trans(lang, "documents.passport.given_names")}</strong>
                                    {document.given_names}

                                    <strong>{Translator.trans(lang, "documents.passport.date_of_birth")}</strong>
                                    {document.date_of_birth}
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid">
                            <div className="row padding-row-or-col">
                                <div className="col-4">
                                    <strong>{Translator.trans(lang, "documents.passport.type")}</strong>
                                    {document.type}
                                </div>

                                <div className="col-4">
                                    <strong>{Translator.trans(lang, "documents.passport.code")}</strong>
                                    {document.code}
                                </div>

                                <div className="col-4">
                                    <strong>{Translator.trans(lang, "documents.passport.number")}</strong>
                                    {document.number}
                                </div>

                                <div className="col padding-row-or-col">
                                    <strong>{Translator.trans(lang, "documents.passport.authority")}</strong>
                                    {document.authority}

                                    <strong>{Translator.trans(lang, "documents.passport.date_of_birth")}</strong>
                                    {document.date_of_birth}

                                    <strong>{Translator.trans(lang, "documents.passport.date_of_issue")}</strong>
                                    {document.date_of_issue}

                                    <strong>{Translator.trans(lang, "documents.passport.date_of_expiry")}</strong>
                                    {document.date_of_expiry}

                                    {
                                        document.hasOwnProperty('representative_office') ?
                                            (
                                                <div className="padding-row-or-col">
                                                    <strong>{Translator.trans(lang, "documents.passport.representative_office")}</strong>
                                                    {document.representative_office}
                                                </div>
                                            )
                                        : ''
                                    }

                                    {
                                        document.type === 'PS' && document.authority === 'Komórka Spraw Zewnętrznych' ?
                                            (
                                                <div className="padding-row-or-col" style={{opacity: 0.6}}>
                                                    PASZPORT SŁUŻBOWY KOMÓRKI SPRAW ZEWNĘTRZNYCH<br />
                                                    SERVICE PASSPORT OF THE UNIT OF EXTERNAL AFFAIRS
                                                </div>
                                            )
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''
            }


            {
                show && document !== null && document.stamps !== null && Object.keys(document.stamps).length > 0 ? (
                    <div className="card-footer">
                        <strong>{Translator.trans(lang, "documents.passport.stamps_and_stickers")}</strong><br />
                        {
                            Object.keys(document.stamps).map((stamp, x) => {
                                const styles = {width: '75px', height: '100px', margin: '0 25px 25px 0', fontSize: '0.8rem', align: 'center', display: 'inline-block'};

                                if (stamp == 'andra') {
                                    return (
                                        <div style={styles} key={x}>
                                            <img src={stampAndra} alt={stamp} />

                                            {document.stamps[stamp]}
                                        </div>
                                    );
                                } else if (stamp == 'kolasa') {
                                    return (
                                        <div style={styles} key={x}>
                                            <img src={stampKolasa} alt={stamp} />

                                            {document.stamps[stamp]}
                                        </div>
                                    );
                                } else if (stamp == 'main') {
                                    return (
                                        <div style={styles} key={x}>
                                            <img src={stampMain} alt={stamp} />

                                            {document.stamps[stamp]}
                                        </div>
                                    );
                                } else if (stamp == 'mouse') {
                                    return (
                                        <div style={styles} key={x}>
                                            <img src={stampMouse} alt={stamp} />

                                            {document.stamps[stamp]}
                                        </div>
                                    );
                                } else if (stamp == 'panda') {
                                    return (
                                        <div style={styles} key={x}>
                                            <img src={stampPanda} alt={stamp} />

                                            {document.stamps[stamp]}
                                        </div>
                                    );
                                } else if (stamp == 'rabbit') {
                                    return (
                                        <div style={styles} key={x}>
                                            <img src={stampRabbit} alt={stamp} />

                                            {document.stamps[stamp]}
                                        </div>
                                    );
                                } else if (stamp == 'test') {
                                    return (
                                        <div style={styles} key={x}>
                                            <img src={stampTest} alt={stamp} />

                                            {document.stamps[stamp]}
                                        </div>
                                    );
                                } else if (stamp == 'xmk') {
                                    return Object.keys(document.stamps[stamp]).map((date, x) => {
                                        return (
                                            <div style={styles} key={x}>
                                                <img src={stampXMK} alt={stamp} />

                                                {document.stamps[stamp][date]}
                                            </div>
                                        );
                                    });
                                }
                            })
                        }
                    </div>
                ) : ''
            }
        </div>
    );
}
