import React from "react";
import '../styles/preloader.css';
import DefaultLoader from "./loaders/DefaultLoader";


export default function Preloader({show}: any)
{
    return (
        <div id="preloader" style={{display: show ? 'block' : 'none'}}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <DefaultLoader />
                    </div>
                </div>
            </div>
        </div>
    );
}
